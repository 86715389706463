import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProcessingDetailsFacade } from './processing-details.facade';
import { ProcessingDetailsAdapter, ProcessingDetailsComponentData } from './processing-details.model';
import { parseNumber } from 'common';
import { ApplicationDocumentData } from '../../../../domain/models/application.model';
import { SubmissionDetailsActionsService } from '../../actions/submission-details-actions.service';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';

@UntilDestroy()
@Component({
  selector: 'ifbp-processing-details',
  templateUrl: './processing-details.component.html',
  styleUrls: ['./processing-details.component.scss'],
  providers: [ProcessingDetailsFacade, ProcessingDetailsAdapter]
})
export class ProcessingDetailsComponent implements OnInit {
  viewData: ProcessingDetailsComponentData;
  readonly BrokerStatus = BrokerStatus;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly facade: ProcessingDetailsFacade,
    private readonly actions: SubmissionDetailsActionsService,
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  private initData() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = parseNumber(params.get("id"));
      this.facade.getComponentData$(applicationId)
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          this.viewData = data;
          this.actions.markDataAsLoaded(true);
        })
    })
  }

  onDownloadDocument(data: ApplicationDocumentData) {
    this.actions.downloadApplicationDocument(data);
  }
}
