import { Component, Input } from '@angular/core';
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from '../../../../not-interested-action/not-interested-action.model';
import { SubmissionDetailsActionsService } from '../../../actions/submission-details-actions.service';
import { defaultSubmissionDetailsFooterConfigData, SubmissionDetailsFooterConfigData } from '../../../submission-details.model';

@Component({
  selector: 'ifbp-submission-details-footer-section',
  templateUrl: './submission-details-footer-section.component.html',
  styleUrls: ['./submission-details-footer-section.component.scss']
})
export class SubmissionDetailsFooterSectionComponent {
  @Input()
  notInterestedActionData: NotInterestedActionInputData;

  @Input()
  config?: SubmissionDetailsFooterConfigData = defaultSubmissionDetailsFooterConfigData;

  constructor(private readonly actions: SubmissionDetailsActionsService) { }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    this.actions.notInterestedActionComplete(data);
  }

  onClickClose() {
    this.actions.close();
  }
}
