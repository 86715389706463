<article class="container">
  <ng-container *ngIf="viewData">
    <ifbp-submission-details-header-section class="section-container"
                                            [data]="viewData.headerData">
    </ifbp-submission-details-header-section>
    <ifbp-processing-details-banner-section class="section-container"
                                            *ngIf="viewData.bannerData"
                                            [data]="viewData.bannerData">
    </ifbp-processing-details-banner-section>
    <ng-container *ngFor="let section of viewData.sections">
      <ifbp-processing-details-basic-section class="section-container"
                                             [data]="section">
      </ifbp-processing-details-basic-section>
    </ng-container>
    <ifbp-processing-details-documents-section *ngIf="viewData.applicationDocumentsSummary?.bankStatementDocuments?.length > 0 ||
                                                      viewData.applicationDocumentsSummary?.signedApplicationDocuments?.length > 0"
                                               class="section-container"
                                               [bankStatementDocuments]="viewData.applicationDocumentsSummary?.bankStatementDocuments"
                                               [signedApplicationDocuments]="viewData.applicationDocumentsSummary?.signedApplicationDocuments"
                                               (downloadDocument)="onDownloadDocument($event)">
    </ifbp-processing-details-documents-section>
    <ifbp-submission-details-footer-section class="section-container"
                                            [notInterestedActionData]="viewData.notInterestedActionData">
    </ifbp-submission-details-footer-section>
  </ng-container>
</article>
