<section class="section">
  <header>
    <h2 class="heading">{{data.title}}</h2>
  </header>
  <ng-container *ngFor="let subsection of data.subsections">
    <div class="subsection"
         [ngClass]="data.subsections?.length > 1 ? 'subsection--multiple' : 'subsection--single'">
      <p *ngIf="subsection?.title"
         class="subsection__heading">{{subsection.title}}</p>
      <ng-container *ngFor="let row of subsection.rows; even as isEven; last as isLast; index as index; count as count">
        <div class="row"
             *ngIf="isEven">
          <div class="item-wrapper">
            <div class="item">
              <span class="item__label">{{row.label}}</span>
              <span class="item__value">{{row.value}}</span>
            </div>
            <ng-container *ngTemplateOutlet="divider"></ng-container>
          </div>
          <ng-container *ngIf="(index + 1) <= (count - 1); else emptyWrapper">
            <div class="item-wrapper">
              <div class="item">
                <span class="item__label">{{subsection.rows[index + 1].label}}</span>
                <span class="item__value">{{subsection.rows[index + 1].value}}</span>
              </div>
              <ng-container *ngTemplateOutlet="divider"></ng-container>
            </div>
          </ng-container>
          <ng-template #emptyWrapper>
            <div class="item-wrapper"></div>
          </ng-template>
          <ng-template #divider>
            <mat-divider *ngIf="!isLast && (index + 1) < (count - 1)"></mat-divider>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-container>
</section>
