import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  MessageService,
  ResourceService,
} from "common";
import { ApiUrls } from "../../../shared/SharedConstants";
import { AppSettings } from "projects/partner/src/app.settings";
import { catchError, map } from "rxjs/operators";
import { defaultObservableErrorHandler } from "projects/partner/src/Tools";
import { SubmissionQueryParams, SubmissionsHintsQueryParams, SubmissionsHintsResponse, SubmissionsQueryResult } from "../domain/models/submissions.model";
import { ApiPaths } from "../../../core/const/api-paths";
import { AccountData, ApplicationData, ApplicationDocumentsQueryResult, ContactQueryResult, ContactsQueryParams, UpdateApplicationPayload } from "../domain/models/application.model";
import { LoanData } from "../domain/models/loan.model";
import { adaptApplicationData, ApplicationResponse } from "./api-models/application-api.model";

@Injectable({
  providedIn: "root",
})
export class SubmissionsRemote {
  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private messageService: MessageService,
    private resourceService: ResourceService
  ) { }

  updateApplicationStage(
    applicationId: number,
    payload: UpdateApplicationPayload,
  ): Observable<ApplicationData> {
    return this.http
      .patch<any>(
        ApiUrls(this.settings).UpdateApplicationStage({
          application: { id: applicationId },
        }),
        payload,
      )
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getSubmissions(params: SubmissionQueryParams): Observable<SubmissionsQueryResult> {
    return this.http.get<SubmissionsQueryResult>(ApiPaths.submission.list, { params: <any>params });
  }

  getSubmissionsHints(params: SubmissionsHintsQueryParams): Observable<string[]> {
    return this.http.get<SubmissionsHintsResponse>(ApiPaths.submission.hints, { params: <any>params })
      .pipe(map(response => (response.values)));
  }

  getApplicationData(id: number): Observable<ApplicationData> {
    return this.http
      .get<ApplicationResponse>(ApiPaths.submission.application(id))
      .pipe(
        catchError(defaultObservableErrorHandler(this.messageService)),
        map(response => adaptApplicationData(response))
      );
  }

  getAccountData(id: number): Observable<AccountData> {
    return this.http
      .get<AccountData>(ApiPaths.submission.applicationAccount(id))
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getApplicationDocuments(id: number): Observable<ApplicationDocumentsQueryResult> {
    return this.http
      .get<ApplicationDocumentsQueryResult>(ApiPaths.submission.applicationDocuments(id))
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getContactsData(params: ContactsQueryParams): Observable<ContactQueryResult> {
    return this.http
      .get<ContactQueryResult>(ApiPaths.submission.contacts, { params: <any>params })
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  downloadApplicationDocument(applicationId: number, documentId: number, filename: string): void {
    const url = ApiPaths.submission.applicationDocument(applicationId, documentId)
    return this.resourceService.download(url, filename);
  }

  getLoanData(id: number): Observable<LoanData> {
    return this.http.get<LoanData>(ApiPaths.submission.loanAccount(id));
  }
}
