import { ApplicationStage, QueryResult } from "common";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { QueryParamsBase } from "../../shared/filters/filter.model";

export interface SubmissionData {
  id: number
  accountName: string;
  assignedAgent: string;
  firstAgent: string;
  brokerStatus: BrokerStatus;
  stage: ApplicationStage;
  previousStage: ApplicationStage;
  createdOn: Date;
  updatedOn: Date;
  information: string;
  loanId: number;
  loanNumber: string;
}

export interface SubmissionsQueryResult extends QueryResult<SubmissionData> { }

export interface SubmissionQueryParams extends QueryParamsBase {
  accountName?: string;
  brokerStatus?: BrokerStatus[];
  firstAgent?: string;
  assignedAgent?: string;
  createdOn?: string;
  createdOnFrom?: string;
  createdOnTo?: string;
  updatedOn?: string;
  updatedOnFrom?: string;
  updatedOnTo?: string;
}

export interface SubmissionsHintsQueryParams {
  fieldName: SubmissionsHintsFieldName;
  value: string;
  limit?: number;
}

export enum SubmissionsHintsFieldName {
  AccountName = "accountName",
  FirstAgent = "firstAgent",
  AssignedAgent = "assignedAgent",
}

export interface SubmissionsHintsResponse {
  values: string[];
  validationMessage?: string;
}
