import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "common";
import { ApplicationData } from "../models/application.model";

export interface SubmissionsState {
  application: ApplicationData;
}

const initialState: SubmissionsState = null;

@Injectable({
  providedIn: "root",
})
export class SubmissionsStateService extends StateService<SubmissionsState> {
  constructor() {
    super(initialState);
  }

  getApplicationData$(): Observable<ApplicationData> {
    return this.select((state: SubmissionsState) => state?.application);
  }

  setApplicationData(application: ApplicationData): void {
    const newState = {
      ...this.state,
      application,
    };
    this.setState(newState);
  }

  isApplicationDataStateLoaded(id: number): boolean {
    return !!this.state?.application && this.state.application.id === id;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
