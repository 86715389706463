import { Component, Input } from '@angular/core';
import { ProcessingDetailsBannerData } from '../../processing-details.model';

@Component({
  selector: 'ifbp-processing-details-banner-section',
  templateUrl: './processing-details-banner-section.component.html',
  styleUrls: ['./processing-details-banner-section.component.scss']
})
export class ProcessingDetailsDeclinedSectionComponent {
  @Input()
  data: ProcessingDetailsBannerData;
}
