
import { NgModule } from "@angular/core";
import { SubmissionsSharedModule } from "../../../shared/submissions-shared.module";
import { NotInterestedActionModule } from "../../not-interested-action/not-interested-action.module";
import { NotInterestedDialogModule } from "../../not-interested-dialog/not-interested-dialog.module";
import { SubmissionDetailsHeaderSectionComponent } from "./components/submission-details-header-section/submission-details-header-section.component";
import { SubmissionDetailsFooterSectionComponent } from './components/submission-details-footer-section/submission-details-footer-section.component';

@NgModule({
  imports: [
    SubmissionsSharedModule,
    NotInterestedActionModule,
    NotInterestedDialogModule,
  ],
  declarations: [
    SubmissionDetailsHeaderSectionComponent,
    SubmissionDetailsFooterSectionComponent
  ],
  exports: [
    SubmissionsSharedModule,
    SubmissionDetailsHeaderSectionComponent,
    SubmissionDetailsFooterSectionComponent
  ]
})
export class SubmissionDetailsSharedModule { }
