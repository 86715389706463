import { AccountData, ApplicationData } from "../../domain/models/application.model";
import { NotInterestedActionInputData } from "../not-interested-action/not-interested-action.model";

export interface SubmissionDetailsBaseComponentData {
  application: ApplicationData;
  notInterestedActionData: NotInterestedActionInputData;
  account?: AccountData;
  headerData?: SubmissionDetailsHeaderData;
}

export interface SubmissionDetailsHeaderData {
  title: string;
  subtitle: string;
}

export interface SubmissionDetailsFooterConfigData {
  isCloseAvailable: boolean;
  isSubmitActionAvailable: boolean;
  submitActionLabel?: string;
}

export const defaultSubmissionDetailsFooterConfigData = {
  isCloseAvailable: true,
  isSubmitActionAvailable: false
}
