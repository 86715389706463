import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { SubmissionDetailsHeaderData } from "../../submission-details.model";
import { ApplicationData } from "../../../../domain/models/application.model";
import { ProcessingDetailsBannerData } from "./processing-details.model";
import { StringHelper } from "common";
import { AdvisorData } from "../../../../shared/models/advisor.model";

export const getProcessingDetailsHeaderData = (brokerStatus: BrokerStatus, advisorData: AdvisorData): SubmissionDetailsHeaderData | undefined => {
  const contactString = getContactString(advisorData);

  switch (brokerStatus) {
    case BrokerStatus.PROCESSING:
      return getProcessingSubmissionDetailsHeaderData(contactString);

    case BrokerStatus.DORMANT:
      return getDormantSubmissionDetailsHeaderData(contactString);

    case BrokerStatus.DECLINED:
      return getDeclinedSubmissionDetailsHeaderData(contactString);

    case BrokerStatus.NOT_INTERESTED:
      return getNotInterestedSubmissionDetailsHeaderData(contactString);

    default:
      return undefined;
  }
}

const getContactString = (advisorData: AdvisorData): string => {
  if (!advisorData?.fullName || !advisorData?.email || !advisorData?.phone) {
    return '';
  }
  const advisorEmailFormat = `<a href="mailto:${advisorData.email}">${advisorData.email}</a>`;
  const advisorPhoneFormat = `<a href="tel:${advisorData.phone}">${StringHelper.phoneFormat(advisorData.phone)}</a>`;
  return `Contact ${advisorData.fullName} at ${advisorEmailFormat} or ${advisorPhoneFormat} if you have any questions.`;
}

const getProcessingSubmissionDetailsHeaderData = (contactString: string): SubmissionDetailsHeaderData => {
  return {
    title: "Submission in Review",
    subtitle: `We are currently reviewing your submission and will reach out to you shortly. ${contactString}`,
  }
}

const getDormantSubmissionDetailsHeaderData = (contactString: string): SubmissionDetailsHeaderData => {
  return {
    title: "Submission closed",
    subtitle: `The submission was closed due to inactivity. ${contactString}`,
  }
}

const getDeclinedSubmissionDetailsHeaderData = (contactString: string): SubmissionDetailsHeaderData => {
  return {
    title: "Submission declined",
    subtitle: `Unfortunately we are unable to accept this submission. ${contactString}`,
  }
}

const getNotInterestedSubmissionDetailsHeaderData = (contactString: string): SubmissionDetailsHeaderData => {
  return {
    title: "Submission marked as not interested",
    subtitle: `This submission was marked as not interested. Press the Reactivate button below to allow the submission to complete the underwriting process. ${contactString}`,
  }
}

export const getProcessingDetailsBannerData = (application: ApplicationData): ProcessingDetailsBannerData | undefined => {
  switch (application.brokerStatus) {
    case BrokerStatus.DECLINED:
      return getDeclinedSubmissionDetailsBannerData(application);

    case BrokerStatus.NOT_INTERESTED:
      return getNotInterestedSubmissionDetailsBannerData(application);

    default:
      return undefined;
  }
}

const getDeclinedSubmissionDetailsBannerData = (application: ApplicationData): ProcessingDetailsBannerData | undefined => {
  if (!application.declinedReason) {
    return undefined;
  }

  return {
    title: "Declined reason",
    description: `${application.declinedReason}`,
  }
}

const getNotInterestedSubmissionDetailsBannerData = (application: ApplicationData): ProcessingDetailsBannerData | undefined => {
  if (!application.lostReason) {
    return undefined;
  }

  const comments = application.lostReasonAdditionalComments ? `; ${application.lostReasonAdditionalComments}` : '';
  return {
    title: "Not interested reason",
    description: `${application.lostReason}${comments}`,
  }
}
