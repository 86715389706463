import { Address, EntityType, EntityTypeLabel, EnumValueLabelPipe, StringHelper, TimeInBusinessPeriod, TimeInBusinessPeriodLabel } from "common";
import { AccountData, ApplicationData, ApplicationDocumentData, ContactQueryResult } from "../../../../domain/models/application.model";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";

export interface ProcessingDetailsComponentData extends SubmissionDetailsBaseComponentData {
  sections: ProcessingDetailsBasicSectionData[];
  applicationDocumentsSummary?: ApplicationDocumentsSummary;
  contacts: ContactQueryResult;
  bannerData?: ProcessingDetailsBannerData;
}

export interface ApplicationDocumentsSummary {
  bankStatementDocuments: ApplicationDocumentData[];
  signedApplicationDocuments: ApplicationDocumentData[];
}

export interface ProcessingDetailsBasicSectionData {
  title: string;
  subsections: ProcessingDetailsBasicSubSectionData[];
}

export interface ProcessingDetailsBasicSubSectionData {
  title?: string;
  rows: LabelValueData[];
}

export interface LabelValueData {
  label: string;
  value?: string;
}

export interface ProcessingDetailsBannerData {
  title: string;
  description: string;
}

@Injectable()
export class ProcessingDetailsAdapter {
  constructor(
    private readonly enumValueLabelPipe: EnumValueLabelPipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly datePipe: DatePipe) { }

  adaptProcessingDetailsBasicSections = (app: ApplicationData, contacts: ContactQueryResult, account?: AccountData): ProcessingDetailsBasicSectionData[] => {
    const sections: ProcessingDetailsBasicSectionData[] = [
      {
        title: 'Business information',
        subsections: [
          {
            rows: [
              { label: 'Company name', value: app?.accountName },
              { label: 'DBA', value: account?.dba },
              { label: 'Ownership', value: EntityTypeLabel.get(account?.entityType as EntityType) },
              { label: 'Primary phone number', value: StringHelper.phoneFormat(account?.phone as string) },
              { label: 'Business address', value: StringHelper.addressFormat(account?.address as Address) },
              { label: 'EIN', value: account?.ein },
              { label: 'Years in business', value: this.enumValueLabelPipe.transform(account?.timeInBusiness as number, TimeInBusinessPeriod, TimeInBusinessPeriodLabel) },
              { label: 'Business description', value: account?.description },
              { label: 'Monthly sales', value: this.currencyPipe.transform(account?.monthlySales) as string },
              { label: 'Requested amount', value: this.currencyPipe.transform(app?.requestedAmount) as string },
            ]
          },
        ]
      },
    ]

    if (!contacts || contacts.totalCount === 0) {
      return sections;
    }

    sections.push(
      {
        title: 'Owner information',
        subsections: contacts.values.map((contact, index) => {
          return {
            title: `Owner ${index + 1}`,
            rows: [
              { label: 'Name', value: `${contact.firstName} ${contact.lastName}` },
              { label: 'Email', value: contact.email },
              { label: 'Mobile phone number', value: StringHelper.phoneFormat(contact.mobilePhone) },
              { label: 'Home address', value: StringHelper.addressFormat(contact.address) },
              { label: 'Birthdate', value: this.datePipe.transform(contact.birthdate, "MMMM d, YYYY") as string },
            ]
          }
        })
      });

    return sections;
  }
}
