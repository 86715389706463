import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppBarTitleService, parseNumber } from 'common';
import { BrokerStatus, BrokerStatusLabel } from 'projects/partner/src/app/shared/SharedConstants';
import { SubmissionDetailsFacade } from './submission-details.facade';
import { SubmissionDetailsBaseComponentData } from './submission-details.model';
import { SubmissionDetailsHandleActionsService } from './actions/submission-details-handle-actions.service';
import { SubmissionDetailsActionsService } from './actions/submission-details-actions.service';

@UntilDestroy()
@Component({
  selector: 'ifbp-submission-details',
  templateUrl: './submission-details.component.html',
  styleUrls: ['./submission-details.component.scss'],
  providers: [
    SubmissionDetailsFacade,
    SubmissionDetailsActionsService,
    SubmissionDetailsHandleActionsService
  ]
})
export class SubmissionDetailsComponent implements OnInit {
  brokerStatus: BrokerStatus;
  isDataLoaded: boolean;
  readonly BrokerStatus = BrokerStatus;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly facade: SubmissionDetailsFacade,
    private readonly appBarTitleService: AppBarTitleService,
    private readonly actions: SubmissionDetailsActionsService,
    private readonly handleActions: SubmissionDetailsHandleActionsService,
  ) { }

  ngOnInit(): void {
    this.initData();
    this.initActions();
  }

  private initData() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = parseNumber(params.get("id"));
      this.facade.getComponentBaseData$(applicationId)
        .pipe(untilDestroyed(this))
        .subscribe((data: SubmissionDetailsBaseComponentData) => {
          this.brokerStatus = data.application.brokerStatus;
          this.appBarTitleService.title = `${data.account?.name ?? 'N/A'} - ${BrokerStatusLabel.get(this.brokerStatus)}`;
        })
    })
  }

  private initActions() {
    this.handleActions.initActions();
    this.actions.markDataAsLoaded$.pipe(untilDestroyed(this)).subscribe(() => {
      this.isDataLoaded = true;
    })
  }
}
