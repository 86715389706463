<div fxLayout="column"
     fxLayoutAlign="start center"
     class="closing-review-container">
  <mat-card fxLayout="column"
            fxLayoutAlign="start center"
            class="loan-summary-card mat-elevation-z">
    <div class="header">{{ selectedOffer?.productCode | productCodeLabel}} offer selected</div>
    <div fxLayout="row"
         fxLayoutAlign="space-evenly center"
         class="card-row">
      <div fxLayout="column"
           fxLayoutAlign="start center"
           class="card-column">
        <div class="info-text">{{ selectedOffer?.amount | currency : 'USD' : 'symbol' : '1.0-0' }}</div>
        <div class="subheading">{{selectedOffer?.productCode === ProductCode.LOC ? "Credit limit" : "Loan amount"}}</div>
      </div>
      <div fxLayout="column"
           fxLayoutAlign="start center"
           class="card-column">
        <div class="info-text"
             *ngIf="selectedOffer">
          {{
            selectedOffer?.repaymentTerm + (
              selectedOffer?.repaymentTerm > 1
                ? " months"
                : " month"
            )
          }}
        </div>
        <div class="subheading">Term</div>
      </div>
      <div fxLayout="column"
           fxLayoutAlign="start center"
           class="card-column">
        <div class="info-text">{{ selectedOffer?.paymentFrequency | paymentFrequencyLabel }}</div>
        <div class="subheading">Payment frequency</div>
      </div>
    </div>
  </mat-card>
  <div *ngIf="checkoutRequirements?.length > 0"
       class="section-wrapper"
       fxLayout="column"
       fxLayoutGap="12px">
    <span class="closing-documents-subheading">Closing Documents</span>
    <ng-container *ngFor="let checkoutRequirement of checkoutRequirements; let checkoutIndex = index">
      <mat-divider *ngIf="checkoutIndex !== 0"></mat-divider>
      <div *ngIf="closingDocumentsForm"
           class="documents-section-wrapper"
           fxLayout="row"
           fxLayoutGap="12px">
        <mat-icon *ngIf="checkoutRequirement.status === CheckoutRequirementStatus.Approved"
                  class="mat-green">
          check_circle
        </mat-icon>
        <mat-icon *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved"
                  class="mat-gray"
                  fontSet="material-icons-outlined">
          circle
        </mat-icon>
        <div class="files-info-column"
             fxLayout="column"
             fxLayoutGap="6px">
          <span>{{checkoutRequirement.requiredDocument}} {{checkoutRequirement.status === CheckoutRequirementStatus.Required ? "(Required)" : ""}}</span>
          <span *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved; else notesSpan"
                class="mat-gray">Upload any PDF, JPEG, PNG, JPG copies
            {{checkoutRequirement.notes ? ("of most recent month ending: " + checkoutRequirement.notes) : ""}}</span>
          <ng-template #notesSpan>
            <span *ngIf="checkoutRequirement.notes"
                  class="mat-gray">{{checkoutRequirement.notes}}</span>
          </ng-template>
          <ng-container *ngFor="let closingDocument of closingDocumentsForm.get([checkoutRequirement.id])?.value; let documentIndex = index">
            <div class="flex row"
                 fxLayoutAlign="start center">
              <button mat-icon-button
                      type="button"
                      [ngClass]="{
                    'hidden': closingDocument.meta.isExisting
                  }"
                      (click)="removeDocument(checkoutRequirement.id, documentIndex)">
                <mat-icon class="mat-gray">cancel</mat-icon>
              </button>
              <div>{{closingDocument.name}}</div>
            </div>
          </ng-container>
        </div>
        <button *ngIf="checkoutRequirement.status !== CheckoutRequirementStatus.Approved"
                mat-button
                class="upload-button"
                (click)="openUploadDialog(checkoutRequirement.id)">UPLOAD</button>
      </div>
    </ng-container>
  </div>
  <div *ngIf="nextStepRequirements?.length > 0"
       class="section-wrapper"
       fxLayout="column"
       fxLayoutGap="12px">
    <span class="subheading">Next step requirements</span>
    <ng-container *ngFor="let nextStepRequirement of nextStepRequirements; let checkoutIndex = index">
      <mat-divider *ngIf="checkoutIndex !== 0"></mat-divider>
      <div class="documents-section-wrapper"
           fxLayout="row"
           fxLayoutGap="12px">
        <mat-icon class="mat-gray"
                  fontSet="material-icons-outlined">
          circle
        </mat-icon>
        <div class="files-info-column"
             fxLayout="column"
             fxLayoutGap="6px">
          <span>{{nextStepRequirement.requiredDocument}}</span>
          <span *ngIf="nextStepRequirement.notes"
                class="mat-gray">{{nextStepRequirement.notes}}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <ifbp-share-offer *ngIf="selectedOffer"
                    [uuid]="applicationInformation.uuid"
                    [offer]="selectedOffer"
                    [bundleId]="bundleId"
                    [showPlaidLink]="true"
                    [showCustomerRegistration]="false"></ifbp-share-offer>
  <div class="bottom-actions-container"
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <ifbp-not-interested-action [data]="notInterestedActionData"
                                (actionComplete)="onNotInterestedActionComplete($event)">
    </ifbp-not-interested-action>
    <ifc-app-bar-actions [menuMobileAllowed]="false"></ifc-app-bar-actions>
  </div>
</div>
