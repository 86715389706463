<section class="skeleton-loader"
         *ngIf="!isDataLoaded">
  <ngx-skeleton-loader class="skeleton-loader__item"
                       count="5"
                       appearance="line">
  </ngx-skeleton-loader>
  <ngx-skeleton-loader class="skeleton-loader__item"
                       count="5"
                       appearance="line">
  </ngx-skeleton-loader>

  <ngx-skeleton-loader class="skeleton-loader__item"
                       count="5"
                       appearance="line">
  </ngx-skeleton-loader>
</section>

<ng-container *ngIf="brokerStatus">
  <ng-container *ngIf="[BrokerStatus.PROCESSING, BrokerStatus.NOT_INTERESTED, BrokerStatus.DORMANT, BrokerStatus.DECLINED].includes(brokerStatus)">
    <ifbp-processing-details></ifbp-processing-details>
  </ng-container>
  <ng-container *ngIf="[BrokerStatus.OPEN, BrokerStatus.FUNDED, BrokerStatus.CLOSED, BrokerStatus.ABANDONED].includes(brokerStatus)">
    <ifbp-activated-details></ifbp-activated-details>
  </ng-container>
</ng-container>
