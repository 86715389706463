import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NotInterestedActionCompletedData } from "../../not-interested-action/not-interested-action.model";
import { ApplicationDocumentData } from "../../../domain/models/application.model";

@Injectable()
export class SubmissionDetailsActionsService {
  private readonly markDataAsLoadedSubject: Subject<boolean> = new Subject();
  markDataAsLoaded$: Observable<boolean> = this.markDataAsLoadedSubject.asObservable();
  markDataAsLoaded = (isDataLoaded: boolean) => this.markDataAsLoadedSubject.next(isDataLoaded);

  private readonly closeSubject: Subject<void> = new Subject();
  close$: Observable<void> = this.closeSubject.asObservable();
  close = () => this.closeSubject.next();

  private readonly notInterestedActionCompleteSubject: Subject<NotInterestedActionCompletedData> = new Subject();
  notInterestedActionComplete$: Observable<NotInterestedActionCompletedData> = this.notInterestedActionCompleteSubject.asObservable();
  notInterestedActionComplete = (data: NotInterestedActionCompletedData) => this.notInterestedActionCompleteSubject.next(data);

  private readonly downloadApplicationDocumentSubject: Subject<ApplicationDocumentData> = new Subject();
  downloadApplicationDocument$: Observable<ApplicationDocumentData> = this.downloadApplicationDocumentSubject.asObservable();
  downloadApplicationDocument = (data: ApplicationDocumentData) => this.downloadApplicationDocumentSubject.next(data);
}
